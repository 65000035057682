import { configureStore } from "@reduxjs/toolkit";
import characteresReducer from "../features/character/characterSlice";
import towersReducer from "../features/towers/towersSlice";
import twonsReducer from "../features/twons/twonsSlice";
import storyReducer from "../features/story/storySlice";
import weaponsReducer from "../features/weapons/weaponsSlice";

export const store = configureStore({
  reducer: {
    characteres: characteresReducer,
    towers: towersReducer,
    twons: twonsReducer,
    story: storyReducer,
    armor: weaponsReducer,
  },
});
