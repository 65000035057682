import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";

//Import Pages
import Home from "./pages/Home/Home";
import Story from "./pages/Story/Story";
import Game from "./pages/Game/Game";

function App() {
  const [towerArray, setTower] = useState([]);
  const [characterArray, setCharacter] = useState([]);
  const [twonArray, setTown] = useState([]);
  const [armorArray, setArmor] = useState([]);
  const frases_loading = [
    "Forging history, loading epic feats...",
    "Unraveling the mysteries of the past, preparing for greatness...",
    "Tuning into the frequency of bravery and courage...",
    "Loading the ancestral memories of a forgotten kingdom...",
    "Weaving threads of ancient magic, loading the essence of legend...",
    "Imbued with the essence of the past to forge a legendary future...",
    "Assembling the fragments of destiny, loading the legacy of the valiant...",
    "Gathering the power of ancient warriors, loading the strength of a heroic lineage...",
    "Synchronizing the energy of realms, preparing for eternal greatness...",
    "Loading ancestral wisdom, unleashing the power of titans...",
  ];
  const numMedalla = Math.floor(Math.random() * 6) + 1;
  useSelector((state) => state.towers)
    .then((data) => {
      setTower(data);
    })
    .catch((error) => {
      console.error(error);
    });
  useSelector((state) => state.characteres)
    .then((data) => {
      setCharacter(data);
    })
    .catch((error) => {
      console.error(error);
    });

  useSelector((state) => state.twons)
    .then((data) => {
      setTown(data);
    })
    .catch((error) => {
      console.error(error);
    });

  useSelector((state) => state.armor)
    .then((data) => {
      setArmor(data);
    })
    .catch((error) => {
      console.error(error);
    });

  return towerArray.length > 0 &&
    characterArray.length > 0 &&
    twonArray.length > 0 &&
    armorArray.length > 0 ? (
    <div className="container-backgorund">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/story" element={<Story number={numMedalla} />} />
        <Route path="/game" element={<Game />} />
      </Routes>
      <Footer />
    </div>
  ) : (
    <div className="containerLoading">
      <img
        src="/img/background_loading.png"
        alt="backgorund loagind"
        className="backgroundLoading"
      />
      <div className="loading">
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <img className="gifLoading" src="/img/Yuri2.gif" alt="Yuri" />
        <h2>
          {frases_loading[Math.floor(Math.random() * frases_loading.length)]}
        </h2>
      </div>
    </div>
  );
}

export default App;
