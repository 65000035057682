import React from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const pathname = useLocation().pathname;

  React.useEffect(() => {
    const el = document.querySelectorAll(".header--button-text");
    el.forEach((element) => {
      element.classList.remove("actived");
    });
    var obj = document.getElementById(pathname);
    obj.className += " actived";
  }, [pathname]);

  return (
    <>
      <img className="header-back" src="img/header.png" alt="Home Background" />
      <header className="container header">
        <Link to="/">
          <img src="/img/logo_header.png" alt="logo Tower of Myths" className="logo"/>
        </Link>
        <div className="header--menu nav-collapse" >
          <Link to="/">
            <p id="/" className="header--button-text actived">
              Home
            </p>
          </Link>
          <Link to="/story">
            <p id="/story" className="header--button-text">
              Story
            </p>
          </Link>
          <Link to="/game">
            <p id="/game" className="header--button-text">
              Game
            </p>
          </Link>
        </div>
      </header>
      <img
        className="shadow-header"
        src="img/barra_header.png"
        alt="Home Background"
      />
    </>
  );
}
