import { createSlice } from "@reduxjs/toolkit";

const getDataStory = () => {
  return new Promise((resolve, reject) => {
    fetch(
      "https://faas-sfo3-7872a1dd.doserverless.co/api/v1/web/fn-15cef92b-72b5-4b6d-a3ed-5e2b10637a4d/story/getStory"
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          data = data.reverse().map((story) => ({
            title: story.name,
            number: story.number,
            text: story.text,
            actived: false,
          }));
          data[0].actived = true;
          resolve(data);
        } else resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const storySlice = createSlice({
  name: "story",
  initialState: getDataStory,
  reducers: {},
});
export default storySlice.reducer;
