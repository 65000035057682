import styles from "./StoryDeatil.module.css";

function StoryDetail(props) {
  let medalla2 = Math.floor(Math.random() * props.text.length);

  console.log(medalla2);
  return (
    <section className={styles.deatil_container}>
      <img src="img/Story/hoja_story.png" alt="Background Story" />
      <div>
        <h2 className={styles.titleChapter}>{props.number}</h2>
        <h1 className={styles.subtitleChapter}>{props.title}</h1>
        <article className={styles.textChapter}>
          {props.text.map((p, key) => (
            <p key={"parrafo " + key}>
              {p} <br />
              {props.id == props.medal && key == medalla2 && (
                <video
                  className={styles.videoChapter}
                  src="img/Story/biblioteca_medal.mp4"
                  muted
                  autoPlay
                  loop
                ></video>
              )}
            </p>
          ))}
        </article>
      </div>
    </section>
  );
}

export { StoryDetail };
