import React, { useState, useEffect } from "react";
import styles from "./story.module.css";
import StoryArray from "../../data/Story.json";

import { StoryDetail } from "./StoryDetail/StoryDetail";

export default function Story(props) {
  let storyData = StoryArray.story;
  const x = Math.floor(Math.random() * 6);
  const [story, setStory] = useState(storyData);
  const [count, setCount] = useState(0);

  function changeChapter(nextItem) {
    setCount(count + 1);
    let dataUpdate = story;
    // eslint-disable-next-line
    dataUpdate.map((item) => {
      if (item.capitulo === nextItem.capitulo) {
        item.actived = true;
      } else {
        item.actived = false;
      }
    });
    setStory(dataUpdate);
  }

  useEffect(() => {}, [count, story]);
  return (
    <>
      <section className={styles.story}>
        <img
          src="img/Story/fondo_story.png"
          alt="Background Story"
          className={styles.background}
        />
        <div className={styles.container}>
          <div></div>
          <h1 className={styles.title}>Story</h1>

          <div className={styles.content_chapterList}>
            <h2 className={styles.title}>Chapter</h2>
            <img src="img/Story/barra_capitulos.png" alt="barra Story" />
            <div className={styles.menu_container}>
              <img
                className={styles.backgroundLateral}
                src="img/Story/barra_lateral_capitulos.png"
                alt="backgroundLateral"
              />
              <ul>
                {story.map((item) => (
                  <li key={item.capitulo} onClick={() => changeChapter(item)}>
                    <p>{item.capitulo}</p>
                    {item.actived === true ? (
                      <p className={styles.active}>{item.title}</p>
                    ) : (
                      <p>{item.title}</p>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {story.map(
            (item) =>
              item.actived === true && (
                <StoryDetail
                  id={item.numero}
                  title={item.title}
                  number={item.capitulo}
                  text={item.text}
                  key={item.title}
                  medal={props.number}
                />
              )
          )}
        </div>
      </section>
    </>
  );
}
