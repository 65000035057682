import React, { useState } from "react";
import styles from "./home.module.css";
import video from "../../video/video.mp4";
import SocialNetwork from "../../data/SocialNetwork.json";
import Carousel from "nuka-carousel";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Home() {
  const [tower, setTower] = useState([]);
  const [town, setTown] = useState([]);

  useSelector((state) => state.twons)
    .then((data) => {
      setTown(data);
    })
    .catch((error) => {
      console.error(error);
    });

  useSelector((state) => state.towers)
    .then((data) => {
      setTower(data);
    })
    .catch((error) => {
      console.error(error);
    });

  let socialNet = SocialNetwork.socialNetwork;
  return (
    <>
      <section className="content-home">
        <div className={styles.container}>
          <video src={video} muted autoPlay loop></video>
        </div>
        {tower.length > 0 && (
          <div className={styles.container_tower}>
            <h1>Towers of Asknuk</h1>
            <img
              src="img/Home/bar_carrusel.png"
              alt="Logo Tower of Myths"
              className={styles.imgBar}
            />
            <Carousel
              wrapAround
              autoplay={true}
              autoplayInterval={10000}
              className={styles.towers}
              withoutControls={true}
            >
              {tower.map((tower, key) => (
                <div className={styles.tower} key={key}>
                  <div className={styles.towerImg}>
                    <img src={tower.files[0].file.url} alt={tower.name} />
                    <p className={styles.tower_name}>{tower.name}</p>
                  </div>
                  <p className={styles.tower_desc}>{tower.description}</p>
                </div>
              ))}
            </Carousel>
          </div>
        )}
        <div className={styles.container_town}>
          <h1>Towns of Asknuk</h1>
          <img
            src="img/Home/bar_carrusel.png"
            alt="Logo Tower of Myths"
            className={styles.imgBar}
          />
          <Carousel
            wrapAround
            autoplay={true}
            autoplayInterval={10000}
            withoutControls={true}
            className={styles.towns}
          >
            {town.map((town, key) => (
              <div className={styles.town} key={key}>
                <img src={town.image} alt={town.name} />
                <div className={styles.container_town_title}>
                  <p className={styles.town_title}>Town</p>
                  <p className={styles.town_name}>{town.name}</p>
                </div>
                <p className={styles.twon_desc}>{town.description}</p>
              </div>
            ))}
          </Carousel>
        </div>
        <div className={styles.container_networks}>
          <h1>Follow Us</h1>
          <img
            className={styles.imgBar}
            src="img/Home/division.png"
            alt="Barra de división"
          />
          <img
            className={styles.imgDrakie}
            src="img/Home/drakie_redes.png"
            alt="Drakie"
          />
          <div className={styles.socialNetwork}>
            {socialNet.map((social, index) => (
              <div key={index}>
                <a
                  href={social.url}
                  key={index}
                  target="_blank"
                  rel="external nofollow noreferrer"
                >
                  <img
                    className={styles.socialNetworkImg}
                    src={social.imageHome}
                    alt="social.socialNetwork"
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.container_shop}>
          <img
            className={styles.imgBar}
            src="img/Home/barra_tienda.png"
            alt="Barra de división"
          />
          <h1 className={styles.title_shop}>Shop</h1>
          <img
            className={styles.imgBar}
            src="img/Home/2barra_tienda.png"
            alt="Barra de división"
          />
          <Link to="/game#BattleMyths">
            <img
              className={styles.imgShop}
              src="img/Home/BattleMyths.jpg"
              alt=" Presentación armaduras"
            />
          </Link>
        </div>
      </section>
    </>
  );
}
