import styles from "../game.module.css";
import React, { useState } from "react";
export default function Character(props) {
  var characterArray = props.characteres;
  var lengthArray = 0;
  const arrayFiltro = [
    "Humanoid",
    "Principal",
    "Animal",
    "Atrocity",
    "Beast",
    "Tiny",
    "Myth",
  ];
  const [currentPage, SetCurrentPage] = useState(0);
  const [checkedState, setCheckedState] = useState(
    new Array(arrayFiltro.length).fill(false)
  );
  const [filtroArray, setFiltro] = useState([]);

  const searchOnChange = (position) => {
    const filtrados = [];
    SetCurrentPage(0);
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    updatedCheckedState.map((active, index) => {
      if (active === true) {
        filtrados.push(arrayFiltro[index]);
      }
    });
    setFiltro(filtrados);
  };

  function filteredNfts() {
    if (filtroArray.length === 0) {
      lengthArray = characterArray.length;
      return characterArray?.slice(currentPage, currentPage + 24);
    } else {
      const filtered = [];
      characterArray.map((character) => {
        filtroArray.map((filtro) => {
          if (character.tag[0].name === filtro) filtered.push(character);
        });
      });
      lengthArray = filtered.length;
      return filtered?.slice(currentPage, currentPage + 24);
    }
  }

  function nextPage() {
    SetCurrentPage(
      currentPage + 24 >= lengthArray ? currentPage : currentPage + 24
    );
  }
  function prevPage() {
    SetCurrentPage(currentPage - 24 < 0 ? 0 : currentPage - 24);
  }

  return (
    <div className={styles.containerFiltro}>
      <div>
        <img src="/img/Games/icon_character.png" alt="" />
        <p className={styles.titleCharacter}>Characters</p>
      </div>
      <div>
        <div>
          <img src="/img/Games/barra_filtros.png" alt="" />
          <div className="continer2ColUnited">
            <img src="/img/Games/filtro.png" alt="" />
            <p className={styles.subTitleCharacter}>Filtros</p>
          </div>
          {arrayFiltro.map((item, key) => (
            <li key={key}>
              <input
                type="checkbox"
                id={`filtro-checkbox-${key}`}
                name={item}
                value={item}
                checked={checkedState[key]}
                onChange={() => searchOnChange(key)}
              />
              <label htmlFor={`filtro-checkbox-${key}`}>{item}</label>
            </li>
          ))}
        </div>
        <div>
          <div className={styles.containerFiltrado}>
            {filteredNfts()?.map((item, index) => (
              <a
                href={item.marketplace}
                target="_blank"
                rel="external nofollow noreferrer"
                key={index}
              >
                <img src={item.files[1].file.url} alt="" />
              </a>
            ))}
          </div>
          <div className={styles.containerButtonPage}>
            <button onClick={() => prevPage()}>
              <img src="/img/Games/sig_1.png" alt="" />
            </button>
            <p>
              <span>{Math.ceil(currentPage / 24) + 1}</span> de{" "}
              {Math.ceil(lengthArray / 24)}
            </p>
            <button onClick={() => nextPage()}>
              <img src="/img/Games/sig_2.png" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
