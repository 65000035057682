import Character from "./Character/Character";
import Weapon from "./Weapon/Weapon";
import Tower from "./TowerDetail/Tower";
import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function Game() {
  const [tower, setTower] = useState([]);
  const [character, setCharecter] = useState([]);
  const [armor, setArmor] = useState([]);

  useSelector((state) => state.characteres)
    .then((data) => {
      setCharecter(data);
    })
    .catch((error) => {
      console.error(error);
    });

  useSelector((state) => state.towers)
    .then((data) => {
      setTower(data);
    })
    .catch((error) => {
      console.error(error);
    });

  useSelector((state) => state.armor)
    .then((data) => {
      setArmor(data);
    })
    .catch((error) => {
      console.error(error);
    });

  return tower.length && character.length > 0 ? (
    <>
      <Tower towers={tower} characteres={character} />
      <Character characteres={character} />
      <Weapon armor={armor} />
    </>
  ) : (
    <>Loading games..</>
  );
}
