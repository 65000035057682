import React from "react";
import "./Footer.css";
import SocialNetwork from "../../data/SocialNetwork.json";

export default function Footer() {
  let socialNet = SocialNetwork.socialNetwork;
  return (
    <footer className="footer">
      <p>Powered by:</p>
      <div className="footer--container">
        <div className="footer--PoweredBy">
          <a
            href="https://www.sandbox.game/en/"
            target="_blank"
            rel="external nofollow noreferrer"
          >
            <img src="img/Footer/logo_the_sandbox.png" alt="Logo The Sandbox" />
          </a>
          <a
            href="https://asharastudios.com/"
            target="_blank"
            rel="external nofollow noreferrer"
          >
            <img
              src="img/Footer/logo_ashara_studios.png"
              alt="Logo Ashara Studios"
            />
          </a>
        </div>
        <div className="footer--container-PressKit">
          <a
            href="https://asharastudios.notion.site/Press-Kit-for-Tower-of-Myths-198a46c9ddd6429496173a26a6580a49"
            target="_blank"
            rel="external nofollow noreferrer"
            className="footer--PressKit"
          >
            <img
              src="img/Footer/press_kit_normal.png"
              alt="Logo Ashara Studios"
            />
          </a>
        </div>
        <div className="footer--follow">
          <p className="subtitle">FOLLOW US</p>
          <div className="footer--logos">
            {socialNet.map((social, index) => (
              <div key={index}>
                <a
                  href={social.url}
                  key={index}
                  target="_blank"
                  rel="external nofollow noreferrer"
                >
                  <img src={social.image} alt="social.socialNetwork" />
                </a>
              </div>
            ))}
          </div>
          <p>Copyright © 2012 - {new Date().getFullYear()}</p>
          <p>All Rigths Reserved</p>
        </div>
      </div>
    </footer>
  );
}
