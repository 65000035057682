import styles from "../game.module.css";
import { useState, useEffect } from "react";

export default function Tower(props) {
  var arrayTowers = props.towers;
  var arrayCharacter = props.characteres;

  const [idTower, setidTower] = useState(0);
  const [tower, setTower] = useState(arrayTowers[idTower]);

  useEffect(() => {
    setTimeout(function () {
      if (idTower + 1 === arrayTowers.length) {
        setidTower(0);
        setTower(arrayTowers[idTower]);
      } else {
        setidTower(idTower + 1);
        setTower(arrayTowers[idTower]);
      }
    }, 20000);
  });
  return (
    <div className={styles.conteinerTower}>
      <img
        src={tower.files[1].file.url}
        className={styles.backgroundTower}
        alt=""
      />
      <div className={styles.containerDetail}>
        <div></div>
        <div className={styles.detailTower}>
          <h1 className={styles.titleTower}>{tower.name}</h1>
          <img
            className={styles.barTower}
            src="/img/Games/tower-bar.png"
            alt="Barra de titulo"
          />
          <div className={styles.conteinerTitleMyth}>
            <h2>{tower.mythName}&nbsp;</h2>
            <p> / Myths</p>
          </div>
          <p>{tower.description}</p>
          <div className="continer2Col">
            <div className="continer2ColUnited top6">
              <img src="/img/Games/simbolo.png" alt="Icono de Simbolo" />
              <h3 className={styles.subtitle2Tower}>Symbol: {tower.symbol}</h3>
              <div></div>
              <img
                src={tower.files[2].file.url}
                alt="Barra de titulo"
                className={styles.symbolGIF}
              />
            </div>
            <div className="continer2ColUnited top6">
              <img src="/img/Games/plaga.png" alt="Icono de Simbolo" />
              <h3 className={styles.subtitle2Tower}>Plague: {tower.plague}</h3>
              <div></div>
              <p>{tower.plagueDescription}</p>
            </div>
          </div>
          <div className={styles.containerFlex}>
            <div id="relic">
              <div className={styles.continerStuff}>
                <img
                  src="./img/Games/box.png"
                  className={styles.imgBox}
                  alt="marco de la imagen"
                />
                <img
                  src={tower.files[4].file.url}
                  className={styles.imgStuff}
                  alt="reliquia"
                />
              </div>
              <h4 className={styles.titleStuff}>Relic</h4>
              <p className={styles.nameStuff}>{tower.symbol}</p>
            </div>
            <div id="Egg">
              <div className={styles.continerStuff}>
                <img
                  src="./img/Games/box.png"
                  className={styles.imgBox}
                  alt="marco de la imagen"
                />
                <img
                  src={tower.files[3].file.url}
                  className={styles.imgStuff}
                  alt="egg"
                />
              </div>
              <h4 className={styles.titleStuff}>Egg</h4>
            </div>
            {arrayCharacter.length > 0 && tower.atrocityName !== "" ? (
              <div id="Atrocity">
                <div className={styles.continerStuff}>
                  <img
                    src="./img/Games/box.png"
                    className={styles.imgBox}
                    alt="marco de la imagen"
                  />
                  <img
                    src={
                      arrayCharacter.filter(
                        (character) => character.name === tower.atrocityName
                      )[0].files[0].file.url
                    }
                    className={styles.imgStuff}
                    alt={tower.atrocityName}
                  />
                </div>
                <h4 className={styles.titleStuff}>Atrocity</h4>
                <p className={styles.nameStuff}>{tower.atrocityName}</p>
              </div>
            ) : (
              <></>
            )}

            {arrayCharacter.length > 0 && tower.beastName !== "" ? (
              <div id="bestia">
                <div className={styles.continerStuff}>
                  <img
                    src="./img/Games/box.png"
                    className={styles.imgBox}
                    alt="marco de la imagen"
                  />
                  <img
                    src={
                      arrayCharacter.filter(
                        (character) => character.name === tower.beastName
                      )[0].files[0].file.url
                    }
                    className={styles.imgStuff}
                    alt={tower.beastName}
                  />
                </div>
                <h4 className={styles.titleStuff}>Beast</h4>
                <p className={styles.nameStuff}>{tower.beastName}</p>
              </div>
            ) : (
              <></>
            )}

            {arrayCharacter.length > 0 && tower.tinyName !== "" ? (
              <div id="Tinny">
                <div className={styles.continerStuff}>
                  <img
                    src="./img/Games/box.png"
                    className={styles.imgBox}
                    alt="marco de la imagen"
                  />
                  <img
                    src={
                      arrayCharacter.filter(
                        (character) => character.name === tower.tinyName
                      )[0].files[0].file.url
                    }
                    className={styles.imgStuff}
                    alt={tower.tinyName}
                  />
                </div>
                <h4 className={styles.titleStuff}>Tiny</h4>
                <p className={styles.nameStuff}>{tower.tinyName}</p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className={styles.containerMenu}>
        <div className="continer2ColUnited top6">
          <img src="/img/Games/tower.png" alt="" />
          <p className={styles.titleTower}>Towers</p>
        </div>
        <div className={styles.containerButtons}>
          {arrayTowers.map((tower, key) =>
            key + 1 === idTower ? (
              <div id={key} key={key}>
                <img
                  src="/img/Games/box_actived.png"
                  alt="boton cuadrado"
                  className={styles.towerActived}
                />
                <img
                  src={tower.files[5].file.url}
                  className={styles.towerActivedImg}
                  alt=""
                />
                <p>{tower.name}</p>
              </div>
            ) : (
              <div id={tower.name + key} key={key}>
                <img
                  src="/img/Games/cuadro_infografia.png"
                  alt="boton cuadrado"
                />
                <p>{tower.name}</p>
              </div>
            )
          )}
        </div>
        <div></div>
      </div>
    </div>
  );
}
