import { createSlice } from "@reduxjs/toolkit";

const getDataWeapons = () => {
  return new Promise((resolve, reject) => {
    fetch(
      "https://faas-sfo3-7872a1dd.doserverless.co/api/v1/web/fn-15cef92b-72b5-4b6d-a3ed-5e2b10637a4d/armors/getArmors"
    )
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const weaponsSlice = createSlice({
  name: "weapons",
  initialState: getDataWeapons,
  reducers: {},
});
export default weaponsSlice.reducer;
